import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const vapidKey = 'BFRE4GIaxGbA_e_7lz2j5lvibRS58uYxO9gO0x9VQ5X-wCmOPebtI29nsY13WpLt2mCHbAIFUxQOxDQdViOB5BY'

function sendTokenToServer(currentToken) {
    if (!isTokenSentToServer(currentToken)) {

        const FD = new FormData();
        FD.append('token', currentToken);

        fetch('https://psql.vahta-dev.ru/frontend/api/push-tokens/save', {
            method: 'POST',
            body: FD,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(responses => responses.json())
            .then((data) => {
              console.log('data',data)
            })

        saveTokenInLocalStorage(currentToken);
    } else {
        console.log('Токен уже отправлен на сервер.');
    }
}

function isTokenSentToServer(currentToken) {
    return window.localStorage.getItem('sentFirebaseMessagingToken') == currentToken;
}

function saveTokenInLocalStorage(currentToken) {
    window.localStorage.setItem( 
        'sentFirebaseMessagingToken',
        currentToken ? currentToken : ''
    );
}

function requestPermission() {
    console.log("Requesting permission...");
    Notification.requestPermission().then((permission) => {
        console.log('permission',permission)
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // alert('granted')
        const notification = new Notification("granted Notification");
      }else {
        alert('chill')
        const notification = new Notification("Hi there!");
      }
    });
  }

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyAVY_iWiTiVwMcMEyWuRPT_f7jjoDwlfWg",
  authDomain: "vahta-a16b2.firebaseapp.com",
  projectId: "vahta-a16b2",
  storageBucket: "vahta-a16b2.appspot.com",
  messagingSenderId: "175127933400",
  appId: "1:175127933400:web:2156a6a065eeb4a09c82ad",
  measurementId: "G-7FSGEJQPXZ",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
  new Notification("Notification")
});
getToken(messaging,{ vapidKey: vapidKey })
.then(function (currentToken) {
    if (currentToken) {
        sendTokenToServer(currentToken);
    } else {
        console.warn('Не удалось получить токен.');
        saveTokenInLocalStorage(false);
    }
})
.catch(function (err) {
    console.warn('При получении токена произошла ошибка.', err);
    saveTokenInLocalStorage(false);
});
// window.messaging = messaging
// requestPermission()
// initiNotification()
