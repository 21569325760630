<template>
  <div class="v-textarea" :class="{ 'v-textarea_error': error }">
      <span
          class="v-textarea__placeholder"
          :class="{ 'v-textarea__placeholder_focus': focus || inputVal }"
      >{{ placeholder }}
    </span>
    <textarea
        class="v-textarea__textarea"
        v-model="inputVal"
        @focus="focus = true"
        @blur="focus = false"></textarea>

  </div>
</template>

<script setup>
import {computed, ref} from "vue";

const props = defineProps({
  modelValue: [String, Number],
  placeholder: {
    type: String,
  },
  error: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const inputVal = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const focus = ref(false);
</script>

<style scoped lang="scss">
.v-textarea {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background-color: $light;
  border: 1px solid $light-gray-2;

  &_error {
    border: 1px solid $red;
  }

  &__textarea {
    width: 100%;
    height: 100%;
    padding: 31px 16px 12px 16px;
    background-color: transparent;
    font-size: 14px;
    resize: none;
  }

  &__placeholder {
    position: absolute;
    top: 12px;
    left: 16px;
    font-size: 14px;
    font-weight: 400;
    transition: 0.3s;

    &_focus {
      color: $gray;
      font-size: 12px;
    }
  }
}
</style>
